type Elements = {
  module: HTMLElement | null;
  eventsLoadMore: HTMLButtonElement | null;
  eventsResultsList: HTMLUListElement | null;
  peopleLoadMore: HTMLButtonElement | null;
  peopleResultsList: HTMLUListElement | null;
  postsLoadMore: HTMLButtonElement | null;
  postsResultsList: HTMLUListElement | null;
}

type Search = {
  postsPerPage: number;
  elements: Elements;
  init: () => void;
  bindEvents: () => void;
  getResults: (page: number, type: string) => void;
  handleLoadMoreEventsClick: () => void;
  handleLoadMorePeopleClick: () => void;
  handleLoadMorePostsClick: () => void;
};

const search: Search = {
  postsPerPage: 10,

  elements: {
    module: document.querySelector('.site-search__contents'),
    eventsLoadMore: document.querySelector('.site-search__events button[data-action="load-more"]'),
    eventsResultsList: document.querySelector('.site-search__events .site-search__results'),
    peopleLoadMore: document.querySelector('.site-search__people button[data-action="load-more"]'),
    peopleResultsList: document.querySelector('.site-search__people .site-search__results'),
    postsLoadMore: document.querySelector('.site-search__posts button[data-action="load-more"]'),
    postsResultsList: document.querySelector('.site-search__posts .site-search__results'),
  },

  init() {
    if(!this.elements.module) return;

    this.bindEvents();
  },

  bindEvents() {
    this.elements.eventsLoadMore?.addEventListener('click', () => this.handleLoadMoreEventsClick());
    this.elements.peopleLoadMore?.addEventListener('click', () => this.handleLoadMorePeopleClick());
    this.elements.postsLoadMore?.addEventListener('click', () => this.handleLoadMorePostsClick());
  },

  handleLoadMoreEventsClick() {
    const button = this.elements.eventsLoadMore;
    const newPage = parseInt(button?.dataset.page || '1', 10) + 1;
    this.getResults(newPage, 'events');
  },

  handleLoadMorePeopleClick() {
    const button = this.elements.peopleLoadMore;
    const newPage = parseInt(button?.dataset.page || '1', 10) + 1;
    this.getResults(newPage, 'people');
  },

  handleLoadMorePostsClick() {
    const button = this.elements.postsLoadMore;
    const newPage = parseInt(button?.dataset.page || '1', 10) + 1;
    this.getResults(newPage, 'posts');
  },

  getResults(page, type) {
    const button = type === 'events' ? this.elements.eventsLoadMore : type === 'people' ? this.elements.peopleLoadMore : this.elements.postsLoadMore;
    const resultsList = type === 'events' ? this.elements.eventsResultsList : type === 'people' ? this.elements.peopleResultsList : this.elements.postsResultsList;
    const showingCount = page * this.postsPerPage;

    window.jQuery.ajax({
      method: 'POST',
      url: window.curtis.ajaxurl,
      data: {
        action: 'action_get_search',
        query: button?.dataset.query,
        events: this.elements.eventsLoadMore?.dataset.page || 1,
        people: this.elements.peopleLoadMore?.dataset.page || 1,
        posts: this.elements.postsLoadMore?.dataset.page || 1,
        [type]: page,
        type
      },
    })
    .success((success: string) => {
      const data = JSON.parse(success);

      if(resultsList && data.results && page > 1) {
        resultsList.innerHTML = `${resultsList.innerHTML}${data.results}`;
      } else if(resultsList && data.results && page === 1) {
        resultsList.innerHTML = data.results;
      } else if(resultsList && !data.results) {
        resultsList.innerHTML = '<li class="not-found">No results found</li>';
        button?.classList.add('hidden');
      }

      /* update the page number */
      button?.setAttribute('data-page', `${page}`);

      /* hide the load button if we have all results */
      if(page === -1 || showingCount >= data.total) {
        button?.classList.add('hidden');
      }
    })
    .fail((_jqXHR: JQuery.jqXHR, _textStatus: string, errorThrown: string) => console.log(errorThrown));
  }

};

export default search;
