import { Options } from '@/types/general';

type Elements = {
  module: HTMLDivElement | null;
  selects: HTMLSelectElement[];
  season: HTMLSelectElement | null;
  month: HTMLSelectElement | null;
  type: HTMLSelectElement | null;
  instrument: HTMLSelectElement | null;
  toggle: HTMLButtonElement | null;
};

type SelectedOptions = {
  season: string | undefined;
  month: string | undefined;
  type: string | undefined;
  instrument: string | undefined;
  view: string | undefined;
};

type EventsList = {
  elements: Elements;
  initialMonthValue: string;
  init: () => void;
  bindEvents: () => void;
  updateEvents: (list: string) => void;
  updateCalendar: (calendar: string) => void;
  updateOptions: (select: HTMLSelectElement, options: Options) => void;
  getSelectedOptions: () => SelectedOptions;
  adjustSelectWidth: (selectElement: HTMLSelectElement) => void;
  fetchEvents: (select: HTMLSelectElement) => Promise<void>;
  updateURLParameters: (options: SelectedOptions) => void;
  setInitialStateFromURL: () => void;
};

const eventsList: EventsList = {
  elements: {
    module: null,
    selects: [],
    season: null,
    month: null,
    type: null,
    instrument: null,
    toggle: null,
  },

  initialMonthValue: '',

  init() {
    this.elements.module = document.querySelector('.module--events-list');
    this.elements.selects = Array.from(document.querySelectorAll<HTMLSelectElement>('.module--events-list select'));
    this.elements.season = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-season"]');
    this.elements.month = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-month"]');
    this.elements.type = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-type"]');
    this.elements.instrument = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-instrument"]');
    this.elements.toggle = document.querySelector<HTMLButtonElement>('.module--events-list button[data-toggle="view"]');

    if(!this.elements.module) return;

    // Store initial month value
    if (this.elements.month) {
      this.initialMonthValue = this.elements.month.value;
    }

    this.setInitialStateFromURL();
    this.bindEvents();
    if (this.elements.season) {
      this.fetchEvents(this.elements.season);
    }
  },

  bindEvents() {
    window.addEventListener('load', () => {
      if (this.elements.selects.length) {
        this.elements.selects.forEach((select) => {
          this.adjustSelectWidth(select);

          select.addEventListener('change', async () => {
            // Update initial month value if month is manually changed
            if (select === this.elements.month) {
              this.initialMonthValue = select.value;
            }
            await this.fetchEvents(select);
            this.adjustSelectWidth(select);
          });
        });
      }

      if (this.elements.toggle) {
        this.elements.toggle.addEventListener('click', async () => {
          if (!this.elements.module || !this.elements.toggle || !this.elements.month) return;

          const currentView = this.elements.module.dataset.view;
          const newView = currentView === 'list' ? 'calendar' : 'list';

          if (newView === 'calendar') {
            // Store current month value before switching to calendar
            const currentMonthValue = this.elements.month.value;

            // When switching to calendar, set to current month if no explicit selection
            if (currentMonthValue === '' || currentMonthValue === 'upcoming' || currentMonthValue === 'all') {
              const today = new Date();
              const year = today.getFullYear();
              const month = (today.getMonth() + 1).toString().padStart(2, '0');
              const currentMonthDate = `${year}${month}01`;

              const options = Array.from(this.elements.month.options);
              const currentMonthOption = options.find(option =>
                option.value.startsWith(currentMonthDate) &&
                option.value !== 'all' &&
                option.value !== 'upcoming'
              );

              if (currentMonthOption) {
                this.elements.month.value = currentMonthOption.value;
              } else {
                // Find next available month
                const futureOption = options.find(option =>
                  option.value > currentMonthDate &&
                  option.value !== 'all' &&
                  option.value !== 'upcoming'
                );
                if (futureOption) {
                  this.elements.month.value = futureOption.value;
                }
              }
            }
          } else {
            // When switching back to list, return to initial state unless there was
            // an explicit month selection before any view switching
            if (this.initialMonthValue === '' ||
                this.initialMonthValue === 'upcoming' ||
                this.initialMonthValue === 'all') {
              this.elements.month.value = 'upcoming';
            } else {
              // Keep the explicitly selected month
              this.elements.month.value = this.initialMonthValue;
            }
          }

          // Update the view
          this.elements.module.dataset.view = newView;
          this.elements.toggle.textContent = newView === 'list' ? 'Calendar View' : 'List View';

          // Fetch events with the new state
          await this.fetchEvents(this.elements.month);

          // Update URL parameters after the view change
          this.updateURLParameters(this.getSelectedOptions());
        });
      }
    });

    window.addEventListener('popstate', () => {
      this.setInitialStateFromURL();
      this.fetchEvents(this.elements.season!);
    });
  },

  updateEvents(list: string) {
    const content = this.elements.module?.querySelector<HTMLUListElement>('.module__content--list ul');
    if (!content) return;
    content.innerHTML = list;
  },

  updateCalendar(calendar: string) {
    const content = this.elements.module?.querySelector<HTMLDivElement>('.module__content--calendar');
    if (!content) return;
    content.innerHTML = calendar;
  },

  updateOptions(select: HTMLSelectElement, options: Options) {
    if (!this.elements.month || !this.elements.type || !this.elements.instrument) return;

    switch (select.dataset.filter) {
      case 'event-season':
        this.elements.month.innerHTML = options.months.map((month) => `<option value="${month.value}">${month.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.month);
        this.elements.type.innerHTML = options.types.map((type) => {
          let output = `<option value="${type.value}">${type.label}</option>`;
          if (type.children) {
            output += type.children.map((child) => `<option value="${child.value}">- ${child.label}</option>`).join('');
          }
          return output;
        }).join('');
        this.adjustSelectWidth(this.elements.type);
        this.elements.instrument.innerHTML = options.instruments.map((instrument) => `<option value="${instrument.value}">${instrument.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.instrument);
        break;
      case 'event-month':
        this.elements.type.innerHTML = options.types.map((type) => {
          let output = `<option value="${type.value}">- ${type.label}</option>`;
          if (type.children) {
            output += type.children.map((child) => `<option value="${child.value}">${child.label}</option>`).join('');
          }
          return output;
        }).join('');
        this.adjustSelectWidth(this.elements.type);
        this.elements.instrument.innerHTML = options.instruments.map((instrument) => `<option value="${instrument.value}">${instrument.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.instrument);
        break;
      case 'event-type':
        this.elements.instrument.innerHTML = options.instruments.map((instrument) => `<option value="${instrument.value}">${instrument.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.instrument);
        break;
    }
  },

  getSelectedOptions(): SelectedOptions {
    return {
      season: this.elements.season?.value,
      month: this.elements.month?.value,
      type: this.elements.type?.value,
      instrument: this.elements.instrument?.value,
      view: this.elements.module?.dataset.view
    };
  },

  adjustSelectWidth(selectElement: HTMLSelectElement) {
    if (!this.elements.module) return;

    const tmpSelect = document.createElement('select');
    const tmpOption = document.createElement('option');

    tmpOption.textContent = selectElement.options[selectElement.selectedIndex].text;
    tmpSelect.appendChild(tmpOption);

    tmpSelect.style.visibility = 'hidden';
    tmpSelect.style.position = 'absolute';
    tmpSelect.style.width = 'auto';

    this.elements.module.appendChild(tmpSelect);
    const width = tmpSelect.clientWidth;
    this.elements.module.removeChild(tmpSelect);
    selectElement.style.width = `${width}px`;
  },

  async fetchEvents(select: HTMLSelectElement) {
    this.elements.module?.classList.add('loading');

    try {
      const response = await window.jQuery.ajax({
        method: 'POST',
        url: window.curtis.ajaxurl,
        data: {
          action: 'action_get_events',
          selected: this.getSelectedOptions()
        },
      });

      const data = JSON.parse(response) as { options: Options; results: string; calendar: string };
      this.updateOptions(select, data.options);
      this.updateEvents(data.results);
      this.updateCalendar(data.calendar);
      this.updateURLParameters(this.getSelectedOptions());
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      this.elements.module?.classList.remove('loading');
    }
  },

  updateURLParameters(options: SelectedOptions) {
    const searchParams = new URLSearchParams();
    Object.entries(options).forEach(([ key, value ]) => {
      if (value) {
        searchParams.set(key, value);
      }
    });

    const newURL = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState(null, '', newURL);

    if (this.elements.selects.length) {
      this.elements.selects.forEach(select => this.adjustSelectWidth(select));
    }
  },

  setInitialStateFromURL() {
    const searchParams = new URLSearchParams(window.location.search);

    const season = searchParams.get('season');
    if (season && this.elements.season) {
      this.elements.season.value = season;
    }

    const view = searchParams.get('view') || 'list';
    const month = searchParams.get('month');

    if (this.elements.month) {
      if (!month) {
        // Set default based on view
        if (view === 'list') {
          this.elements.month.value = 'upcoming';
        } else {
          // For calendar, find current month option
          const today = new Date();
          const year = today.getFullYear();
          const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');
          const currentMonthDate = `${year}${currentMonth}01`;

          const options = Array.from(this.elements.month.options);
          const currentMonthOption = options.find(option =>
            option.value.startsWith(currentMonthDate) &&
            option.value !== 'all' &&
            option.value !== 'upcoming'
          );

          if (currentMonthOption) {
            this.elements.month.value = currentMonthOption.value;
          } else {
            // Find next available month
            const futureOption = options.find(option =>
              option.value > currentMonthDate &&
              option.value !== 'all' &&
              option.value !== 'upcoming'
            );
            if (futureOption) {
              this.elements.month.value = futureOption.value;
            }
          }
        }
      } else {
        this.elements.month.value = month;
      }

      // Store the initial month value
      this.initialMonthValue = this.elements.month.value;
    }

    const type = searchParams.get('type');
    if (type && this.elements.type) {
      this.elements.type.value = type;
    }

    const instrument = searchParams.get('instrument');
    if (instrument && this.elements.instrument) {
      this.elements.instrument.value = instrument;
    }

    if (this.elements.module) {
      this.elements.module.dataset.view = view;
      if (this.elements.toggle) {
        this.elements.toggle.textContent = view === 'list' ? 'Calendar View' : 'List View';
      }
    }

    if (this.elements.selects.length) {
      this.elements.selects.forEach(select => this.adjustSelectWidth(select));
    }
  },
};

export default eventsList;
